import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function PetFinderX() {
  return (
	<Layout>
	  <Helmet>
		<title>PetFinderX | Nathan Pasko</title>
		<meta name="description" content="PetFinderX is a browser-based virtual pet for the web. Cryptic and obtuse, PetFinderX purports to manage interactions with massive celestial bodies." />
	  </Helmet>
	  <div className="project-page">
		<ul className="horizontal-scroll">
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/dulmo.png"
				alt=""
				height="1024"
				width="1030"
			  />
			</picture>
		  </li>
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/vrybe2.png"
				height="900"
				width="1029"
			  />
			</picture>
		  </li>
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/vrybe1.png"
				height="900"
				width="1030"
			  />
			</picture>
		  </li>
		  <li>
			<picture>
			  <img
				src="http://avknights.com/pic/cav/mulmasha.png"
				alt=""
				height="900"
				width="1030"
			  />
			</picture>
		  </li>
		</ul>
		<h1>PetFinderX</h1>
		<p>
		  Manage relationships with cosmic entities using this browser-based tool.
		</p>
		<p>
			PetFinderX is a browser-based virtual pet for the web. Cryptic and obtuse, PetFinderX purports to manage interactions with massive celestial bodies.
		</p>
		<p>
			<a href="https://petfinderx.space/">https://petfinderx.space</a>
		</p>
	  </div>
	</Layout>
  );
}
